main {
  max-width: 600px;
  margin: auto;
  text-align: center;
  /* z-index: 99;
  position: relative; */
}
.app_background{
  background-image: url('online-call.jpg');
  height: 100vh;
  display: flex;
  width: 100%;
  position: relative;
  background-size: cover;
}
/* .app_background::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background-color: #031123ab;
  width: 100%;
  height: 100%;
} */
main h1{
  color: #ffffff;
  font-size: 35px;
}
main button {
  margin-top: 20px;
  background-color: rgb(255 0 0);
  color: #ffffff;
  text-decoration: none;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 22px;
  font-weight: 600;
  border: 1px solid #fff;
}

main button:hover {
  background-color: #2681F2;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
